.container {
  width: 100%;
  height: 100%;
  position: fixed;
  background: #00000080;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 100000;
  backdrop-filter: blur(12px);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;

  .modal {
    width: 380px;
    max-width: 100%;
    max-height: 80%;
    margin: 16px 16px;
    overflow: auto;
    height: auto;
    background-color: #171a1f;
    position: relative;
    border-radius: 16px;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
  }
}
