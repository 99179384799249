.actionsHeader {
  border-bottom: 1px solid var(--color-border-light);
  cursor: auto !important;
  padding-left: var(--space-2);
  padding-right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.compactHeader {
  border: 0;
  padding-left: 0;
}

.actionsHeader button {
  padding-left: 16px;
  padding-right: 16px;
  font-size: 14px;
  font-weight: 400;
}

.divider {
  margin-top: 14px;
  margin-bottom: 14px;
  border: 1px solid var(--color-border-light);
}

.compact {
  display: flex;
  flex-direction: column;
}

.compact > div:first-child {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.compact > div ~ div {
  border-radius: 0;
  margin-top: -1px !important;
}

.compact > div:hover,
.compact > div:global(.Mui-expanded) {
  border-color: var(--color-border-light);
}
