.container {
  height: 100vh;
  padding-top: var(--header-height);
  display: flex;
  overflow: hidden;
  flex-direction: column;
  background-color: var(--color-black-1);
  box-shadow: 1px 0px 0px 0px var(--color-white-10);
}

.container {
  width: 268px;
}

.scroll {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  gap: var(--space-1);
}

.drawer {
  width: 480px;
  max-width: 90vw;
  padding-top: var(--header-height);
  padding-left: var(--space-2);
  padding-right: var(--space-2);
  border-right: 1px solid var(--color-border-light);
  overflow-y: auto;
  height: 100%;
}

.dataWidget {
  margin-top: var(--space-4);
  border-top: 1px solid var(--color-border-light);
}

.noSafeHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  min-height: 100px;
}

.drawerButton {
  position: absolute !important;
  z-index: 2;
  color: var(--color-text-primary);
  padding: 0;
  right: 0;
  margin-top: var(--space-2);
  border-radius: 16px 0 0 16px;
  width: 36px;
  height: 36px;
  border: 0;
  cursor: pointer;
  background-color: var(--color-white-10);
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.drawerButton:hover {
  background-color: var(--color-secondary-background);
}

@media (max-width: 899.95px) {
  .container {
    padding-top: var(--header-height);
    border-right: 1px solid var(--color-border-light);
  }

  .drawer {
    max-width: 90vw;
  }
}
