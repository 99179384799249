.gridRow {
  display: grid;
  grid-template-columns: 25% auto;
  gap: var(--space-1);
  justify-content: flex-start;
  max-width: 900px;
  overflow-x: auto;
}

.gridEmptyRow {
  display: grid;
  grid-template-columns: 35% auto;
  gap: var(--space-1);
  justify-content: flex-start;
  max-width: 900px;
  margin-top: var(--space-1);
  margin-bottom: var(--space-1);
  border-top: 1px solid var(--color-border-light);
}
.title {
  color: var(--color-regular);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  word-break: break-all;
}

.gridRow > * {
  flex-shrink: 0;
}

.valueWrapper {
  min-width: 50%;
  flex-shrink: 0;
}

.rawData {
  display: flex;
  align-items: center;
}

@media (max-width: 599.95px) {
  .gridRow {
    grid-template-columns: 1fr;
    gap: 0;
    margin-top: var(--space-1);
  }
}
