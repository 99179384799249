.buttonExpand {
  margin-top: 8px;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.6px;
}

.date {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.txValue {
  color: var(--color-white-0);
  font-size: 13px !important;
  font-weight: 400;
  line-height: 18.2px;
}

.advancedDetails {
  margin-top: 8px;
}
