.list {
  display: grid;
  gap: 4px;
  padding-left: 16px;
  padding-right: 16px;
}

.icon {
  min-width: 0;
  margin-right: var(--space-1);
}

.icon svg {
  width: 20px;
  height: 20px;
}

.listItemButton {
  border-radius: 6px;
  padding: 6px 16px;
}

.list :global .MuiListItemButton-root {
  color: var(--color-text-primary);
}

[data-theme='dark'] .list :global .Mui-selected {
  background-color: var(--color-primary-10);
}

.list :global .MuiListItemButton-root:hover {
  border-radius: 6px;
  background-color: var(--color-primary-5);
}

.list :global .Mui-selected {
  border-radius: 6px;
  background-color: var(--color-background-main);
}

.listItemButton :global .beamer_icon.active {
  top: auto;
  left: 28px;
  bottom: 10px;
  width: 6px;
  height: 6px;
  color: transparent;
}
.badgeWrapper {
  display: flex;
  padding: 4px 10px 4px 4px;
  align-items: center;
  gap: 4px;
  border-radius: 40px;
  background: var(--color-primary-10);

  color: var(--color-white-0);
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}
.badgeStatusWrapper {
  width: 16px;
  height: 16px;
  border-radius: 16px;
  background-color: #3193e44d;
  border: 4px solid #3193e44d;
}
.badgeStatus {
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background-color: var(--color-primary);
}
