.container {
  padding: 5vh 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex: 1;
}
.round {
  border-radius: 72px;
  border: 1px solid var(--color-700);
  display: flex;
  width: 64px;
  height: 64px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
