.connectModal {
  gap: 12px;

  .title {
    font-size: 18px;
    margin-bottom: 4px;
    font-weight: bold;
  }

  .closeBtn {
    cursor: pointer;
    position: absolute;
    top: 16px;
    right: 16px;
  }

  .backBtn {
    cursor: pointer;
    position: absolute;
    top: 16px;
    left: 16px;
  }

  .walletItem {
    display: flex;
    border-radius: 8px;
    border: 1px solid #3a3a3a;
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    cursor: pointer;

    &:hover {
      background: hsla(0, 0%, 100%, 0.05);
    }

    .walletIcon {
      height: 42px;
      width: 42px;
      border-radius: 4px;
    }

    .walletName {
      flex-grow: 1;
      text-align: center;
      line-height: 42px;
    }
  }

  .connecting {
    height: 204px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .connectingIconContainer {
      position: relative;

      .retryContainer {
        background: #4b5563;
        border-radius: 50%;
        padding: 4px;
        cursor: pointer;
        position: absolute;
        bottom: -12px;
        right: -12px;

        .retryIcon {
          width: 24px;
          height: 24px;
        }
      }
    }

    .connectingIcon {
      width: 60px;
      height: 60px;
      border-radius: 4px;
    }

    .connection {
      font-size: 18px;
      margin-top: 20px;
    }
    .acceptRequest {
      font-size: 14px;
      margin-top: 6px;
      text-align: center;
      white-space: pre-wrap;
      color: rgba(255, 255, 255, 0.4);
    }
    .btnDownload {
      margin-top: 20px;
    }
  }
}
