.aside {
  margin-top: var(--header-height);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 700px;
  max-width: 100vw;
  padding-bottom: var(--space-3);
}

.head {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  padding: var(--space-1) var(--space-4);
  margin-bottom: var(--space-3);
}

.aside hr {
  width: 100%;
  margin: var(--space-3) 0;
}

.txs {
  width: 100%;
}

.txs ul {
  padding: 0 var(--space-3) var(--space-2);
  display: flex;
  flex-direction: column;
  gap: var(--space-1);
  list-style: none;
}

.txs li {
  margin: 0;
  padding: 0;
}

.separator {
  border-left: 1px solid var(--color-border-light);
  height: calc(100% + 31px);
}

.confirmButton {
  margin-top: var(--space-1);
}

.txs svg {
  color: var(--color-border-main);
  transition: color 0.1s ease-in;
  transform: scale(1.2);
}

.txs button:hover svg {
  color: var(--color-primary);
}

.number {
  border-radius: 100%;
  border: 1px solid var(--color-black-3);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin: 12px 0;
}

.close {
  position: absolute;
  right: 0;
  top: var(--space-2);
  z-index: 1;
  padding: var(--space-1);
  color: var(--color-border-main);
}

.details {
  display: flex;
  flex-direction: column;
  gap: var(--space-3);
  padding: var(--space-2);
  margin: calc(-1 * var(--space-2));
  border-top: 1px solid var(--color-secondary-light);
}

.dragHandle {
  cursor: grab;
}

.dragHandle:active {
  cursor: grabbing;
}

.accordion {
  opacity: 1 !important;
}

.accordion :global .MuiAccordionSummary-content {
  width: 100%;
  overflow: hidden;
  margin: 0;
  padding: 12px 0px;
}
.close {
  display: none;
}

@media (max-width: 599.95px) {
  .head {
    flex-direction: column;
    gap: var(--space-2);
    align-items: center;
    justify-content: space-between;
    align-self: stretch;
    padding: var(--space-1) var(--space-2);
    margin-bottom: var(--space-2);
  }
  .txs ul {
    padding: 0 var(--space-2) var(--space-2);
  }
  .close {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    padding: var(--space-1);
    color: var(--color-border-main);
  }
}
