.element {
  color: var(--color-text-primary);
  padding: 0px 4px;
  line-height: 20px;
  font-weight: 500;
  font-size: 12px;
  opacity: 0.9;
}
.hideDesktop {
  display: none;
}

@media (max-width: 599.95px) {
  .divider {
    margin: var(--space-2) 0;
    background-color: var(--color-white-10);
    width: 100%;
    height: 1px;
  }
  .hideDesktop {
    display: block;
  }
}
