.connectedContainer {
  display: flex;
  align-items: center;
}

.buttonContainer {
  display: flex;
  align-items: center;
  text-align: left;
  gap: var(--space-3);
  padding: 0;
  height: 100%;
}

.popoverContainer {
  padding: var(--space-3);
  width: 321px;
  display: flex;
  flex-direction: column;
  gap: var(--space-2);
  border-radius: var(--space-1);
  background: var(--color-800);
  box-shadow: 0px 8px 25px 0px #000;
}

.largeGap {
  gap: var(--space-2);
}

.addressName {
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
}

.profileImg {
  border-radius: var(--space-2);
  width: 32px;
  height: 32px;
}

.profileData {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.address {
  height: 40px;
}

.address div[title] {
  font-weight: bold;
}

.rowContainer {
  align-self: stretch;
  border: 1px solid var(--color-border-light);
  border-radius: 4px;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid var(--color-border-light);
  padding: 12px;
  margin-top: -2px;
}

.row:first-of-type {
  border: 0;
}

.loginButton {
  min-height: 40px;
}

.loginError {
  width: 100%;
  margin: 0;
}

.ph8 {
  padding-left: var(--space-1);
  padding-right: var(--space-1);
}

@media (max-width: 599.95px) {
  .notConnected {
    display: none;
  }
  .popoverContainer {
    padding: var(--space-2);
    width: 100%;
  }
  .hideMobile {
    display: none;
  }
  .buttonContainer {
    gap: var(--space-1);
  }
  .ph8 {
    padding-left: 0;
    padding-right: 0;
  }
}
