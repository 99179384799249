.container {
  display: inline-flex;
  align-items: center;
  gap: var(--space-1);
  color: var(--color-white);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.verticalAlign {
  vertical-align: middle;
}
