.select {
  height: 100%;
}

.select:after,
.select:before {
  display: none;
}

.select *:focus-visible {
  /* outline: 5px auto Highlight; */
  /* outline: 5px auto -webkit-focus-ring-color; */
}

.select :global .MuiSelect-select {
  padding-right: 40px !important;
  padding-left: 16px;
  height: 100%;
  display: flex;
  align-items: center;
}

.select :global .MuiSelect-icon {
  margin-right: var(--space-2);
}

.select :global .Mui-disabled {
  pointer-events: none;
}

.menuItem {
  padding-top: var(--space-1);
  padding-bottom: var(--space-1);
}

.listSubHeader {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: bold;
  line-height: 32px;
}

.newChip {
  font-weight: bold;
  letter-spacing: -0.1px;
  margin-top: -18px;
  margin-left: -14px;
  transform: scale(0.7);
}

.item {
  display: flex;
  align-items: center;
  gap: var(--space-1);
}

.imgContainer {
  position: relative;
}

.testnetIndicator {
  position: absolute;
  right: -4px;
  bottom: 0;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #71DD5F;
  border: 2px solid #1B1C1E
}

.chainName {
  display: flex;
  align-items: center;
  gap: var(--space-1);
  padding: 0;
  min-width: 115px;
  font-size: 14px;
  justify-content: flex-start;
}

@media (max-width: 899.95px) {
  .name {
    display: none;
  }
  .MuiMenu-list :global .name {
    display: block;
  }
}


@media (max-width: 599.95px) {
  .chainName {
    display: none;
  }
  .select :global .MuiSelect-select {
    padding-right: 8px !important;
    padding-left: 8px;
  }
  .select :global .MuiSelect-icon {
    margin-right: 2px;
    margin-right: 0;
    display: none;
  }
  .menuItem .item span span {
    display: block !important;
  }
}
