/* TODO: Apply these styles in the MUI theme once its part of this repository */
.input :global .MuiFormHelperText-root {
  position: absolute;
  bottom: -20px;
}

.input :global .MuiFormLabel-root:not(.MuiInputLabel-shrink) {
  transform: translate(16px, 14px) scale(1);
}

.input :global .MuiInputBase-root {
  background-color: var(--color-black-3);
  border: 1px solid var(--color-white-10);
  border-radius: 6px;
  height: 48px;
  padding: var(--space-1) var(--space-2);
}

.input input {
  padding: 0;
}

.input :global .MuiInputBase-root fieldset {
  border-width: 1px !important;
}

.input :global .MuiInputBase-root:not(.Mui-error) fieldset {
  border-color: var(--color-border-light) !important;
}

@media (max-width: 899.95px) {
  .input :global .MuiFormHelperText-root {
    position: relative;
    bottom: 0;
  }
}
