.container {
  display: flex;
  align-items: center;
  gap: var(--space-1);
  line-height: 1.4;
  width: 100%;
}

.avatarContainer {
  flex-shrink: 0;
  position: relative;
}

.avatarContainer > * {
  width: 100% !important;
  height: 100% !important;
}

.addressContainer {
  display: flex;
  align-items: center;
  gap: 0.25em;
  white-space: nowrap;
}
.column {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.inline {
  display: flex;
  align-items: center;
}
.address {
  color: var(--color-regular);
  font-size: 14px;
  line-height: 20px;
}
.mobileName {
  display: none;
}
@media (max-width: 899.95px) {
  .mobileName {
    display: flex;
  }
}
