:root {
  --color-text-primary: #ffffff;
  --color-text-secondary: #636669;
  --color-text-disabled: #636669;
  --color-primary-dark: #1176ca;
  --color-primary-main: #3193e4;
  --color-primary-light: #a1a3a7;
  --color-secondary-dark: #636669;
  --color-secondary-main: #ffffff;
  --color-secondary-light: #3193e4;
  --color-secondary-background: #3193e41a;
  --color-border-main: #636669;
  --color-border-light: #303033;
  --color-border-background: #101011;
  --color-error-dark: #ac2c3b;
  --color-error-main: #ff5f72;
  --color-error-light: #ffb4bd;
  --color-error-background: #2f2527;
  --color-success-dark: #1176ca;
  --color-success-main: #3193e4;
  --color-success-light: #4b9bdd;
  --color-success-background: #1f2920;
  --color-info-dark: #52bfdc;
  --color-info-main: #5fddff;
  --color-info-light: #b7f0ff;
  --color-info-background: #19252c;
  --color-warning-dark: #c04c32;
  --color-warning-main: #ff8061;
  --color-warning-light: #ffbc9f;
  --color-warning-background: #2f2318;
  --color-background-default: #101011;
  --color-background-main: #101011;
  --color-background-paper: #101011;
  --color-background-light: #1b1e2a;
  --color-backdrop-main: #636669;
  --color-logo-main: #ffffff;
  --color-logo-background: #303033;
  --color-static-main: #101011;

  --color-black-0: #101011;
  --color-black-1: #17181a;
  --color-black-2: #1e1e20;
  --color-black-3: #292a2f;
  --color-primary: #3193e4;
  --color-primary-3: #3193e408;
  --color-primary-5: #3193e40d;
  --color-primary-10: #3193e41a;
  --color-primary-30: #3193e44d;
  --color-regular: #ababab;
  --color-0: #fafafa;
  --color-600: #ababab;
  --color-700: #34353b;
  --color-800: #1b1c1e;
  --color-1200: #101011;
  --color-dark-900: #949494;
  --color-dark-1000: #616161;
  --color-dark-base: #fafafa;
  --color-neutral-700: #b0b3bf;
  --color-neutral-800: #8a8a8a;
  --color-neutral-1000: #616161;
  --color-white: #ffffff;
  --color-white-0: #f0f0f0;
  --color-white-3: #ffffff08;
  --color-white-10: #ffffff1a;
  --color-white-30: #ffffff4d;
  --color-error: #e44e45;
  --color-error-3: #e44e4508;
  --color-error-10: #e44e451a;
  --color-error-30: #e44e454d;
  --color-success: #71dd5f;
  --color-success-3: #71dd5f08;
  --color-success-10: #71dd5f1a;
  --color-success-30: #71dd5f4d;
  --color-orange: #fdac5d;
  --color-orange-10: #fdac5d1a;

  --space-1: 8px;
  --space-2: 16px;
  --space-3: 24px;
  --space-4: 32px;
  --space-5: 40px;
  --space-6: 48px;
  --space-7: 56px;
  --space-8: 64px;
  --space-9: 72px;
  --space-10: 80px;
  --space-11: 88px;
  --space-12: 96px;
}
