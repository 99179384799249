.dialog :global .MuiDialog-paper {
  border-radius: 12px;
  background: var(--color-black-2);
}

.dialog :global .MuiDialogActions-root {
  border-top: 0;
  padding: var(--space-3);
}

.dialog :global .MuiDialogActions-root > :last-of-type:not(:first-of-type) {
  order: 2;
}

.dialog :global .MuiDialogActions-root {
  padding: var(--space-4) var(--space-3) var(--space-3);
}

.dialog :global .MuiDialogActions-root:after {
  /* TODO: check other forms */
  /* content: '';
  order: 1;
  flex: 1; */
}

.dialog :global .MuiDialogTitle-root {
  border-bottom: 0;
  color: var(--color-white);
  font-size: 24px;
  font-weight: 400;
  line-height: 40px;
  padding: var(--space-3) var(--space-3) 0;
}

.dialog :global .MuiDialogContent-root {
  padding: var(--space-2) var(--space-3) 0;
}

@media (min-width: 600px) {
  .dialog :global .MuiDialog-paper {
    min-width: 500px;
    margin: 0;
  }
}
