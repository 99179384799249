.container {
  height: var(--header-height);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  position: relative;
  border-radius: 0 !important;
  background-color: var(--color-black-1);
  box-shadow: 0px 1px 0px 0px #2a2a2a;
  gap: var(--space-1);
  padding: var(--space-1) var(--space-4);
}

.element {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.element :global(.MuiBadge-standard) {
  font-size: 12px;
  width: 18px;
  height: 18px;
  min-width: 18px;
}

[data-theme='dark'] .element :global(.MuiBadge-standard) {
  background-color: var(--color-primary-main);
}

.menuButton,
.logo {
  flex: 1;
  border: none;
  align-items: flex-start;
}

.logo {
  padding: var(--space-2) 0;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
}

.menuButton {
  display: none;
}

.networkSelector {
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px solid var(--color-white-10);
  background: var(--color-white-10);
}

.connectWallet {
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px solid var(--color-white-10);
  background: var(--color-white-10);
  padding: 0;
}
.logoImages {
  display: flex;
  gap: 8px;
  align-items: center;
}
.logoText {
  transform: translateY(3px);
}

.poweredBy {
  display: flex;
  align-items: center;
  gap: 2px;
}

.logoTextContainer {
  display: flex;
  flex-direction: column;
  gap: 1px;
}

@media (max-width: 899.95px) {
  .logo {
    display: none;
  }

  .menuButton {
    display: flex;
  }
}

@media (max-width: 599.95px) {
  .container {
    padding: var(--space-1) var(--space-1);
  }
  .hideMobile {
    display: none;
  }
}
