.container {
  display: flex;
  width: 100%;
  overflow-x: auto;
}

.details {
  width: 66.6%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.shareLink {
  position: absolute;
  right: 16px;
  top: 16px;
}

.loading,
.error,
.txData,
.advancedDetails,
.txModule,
.swapOrder {
  padding: var(--space-2);
}

.txSummary {
  padding: var(--space-2) var(--space-2) var(--space-2) var(--space-6);
}

.swapOrderTransfer {
  border-top: 1px solid var(--color-border-light);
  margin-top: var(--space-2);
  margin-left: calc(var(--space-2) * -1);
  margin-right: calc(var(--space-2) * -1);
  padding: var(--space-2);
  padding-top: var(--space-3);
}
.txData,
.swapOrder {
  border-bottom: 1px solid var(--color-border-light);
}

.txSummary,
.advancedDetails {
  height: 100%;
}

.txSigners {
  display: flex;
  width: 33.3%;
  flex-direction: column;
  padding: var(--space-3);
  border-left: 1px solid var(--color-border-light);
}

.delegateCall .alert {
  width: fit-content;
  padding: 0 var(--space-1);
}

.multiSend,
.swapOrder {
  border-top: 1px solid var(--color-border-light);
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: var(--space-1);
  margin-top: var(--space-2);
}

.buttons > * {
  flex: 1;
}

.buttons button {
  width: 100%;
}

@media (max-width: 599.95px) {
  .container {
    flex-direction: column;
  }

  .details {
    width: 100%;
  }

  .txSigners {
    width: 100%;
    border-left: 0;
    border-top: 1px solid var(--color-border-light);
  }
}
