.icon {
  height: 24px;
  width: 24px;
}

.dot {
  height: 16px;
  width: 16px;
}

.signers {
  padding: 0;
}

.signers::before {
  content: '';
  position: absolute;
  border-left: 2px solid var(--color-border-light);
  left: 15px;
  top: 20px;
  height: calc(100% - 40px);
}

.signers :global .MuiListItem-root:first-of-type {
  padding-top: 0;
}

.signers :global .MuiListItem-root {
  padding-left: 0;
  padding-right: 0;
}

.signers :global .MuiListItemIcon-root {
  color: var(--color-success) !important;
  justify-content: center;
  min-width: 32px;
  padding: var(--space-1) 0;
}

.confirmationsTotal {
  color: var(--color-border-main);
  display: inline;
  font-weight: normal;
}

.listFooter {
  margin-left: var(--space-4);
}
