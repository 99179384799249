.bell {
  display: flex;
  justify-content: center;
  padding: var(--space-1) var(--space-1);
  border-radius: 6px;
}

.bell svg path {
  stroke: var(--color-text-primary);
}

.popoverContainer {
  width: 446px;
  border-radius: 6px;
  border: 1px solid var(--color-700);
  background: var(--color-800);
  box-shadow: 0px 8px 25px 0px #000;
}

@media (max-width: 599.95px) {
  .popoverContainer {
    width: calc(100vw - 30px);
  }
}

.popoverHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--space-3);
  border-bottom: 1px solid var(--color-white-10);
}

.popoverFooter {
  padding: var(--space-2) var(--space-3);
  display: flex;
  align-items: center;
}

.expandButton {
  box-sizing: border-box;
  background-color: var(--color-border-light);
  width: 20px;
  height: 20px;
  margin-left: 10px;
  margin-right: 18px;
  padding: 0;
}

.expandButton > * {
  color: var(--color-border-main);
}

.headerTitle {
  display: flex;
  align-items: center;
}

.unreadCount {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-secondary-light);
  border-radius: 18px;
  margin-left: var(--space-1);
  color: var(--color-static-main);
  text-align: center;
  width: 18px;
  height: 18px;
}

.settingsLink {
  margin-left: auto;
  display: flex;
  align-items: center;
  text-decoration: unset;
  gap: var(--space-1);
}

@media (max-width: 599.95px) {
  .caption {
    margin-left: 8px;
  }
}
