.btn {
  border-radius: 8px;
  padding: 6px 24px;
  font-size: 16px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #000;
  gap: 16px;
  background: white;

  &:hover {
    opacity: 0.85;
  }
  &:disabled {
    background: hsla(0, 0%, 100%, 0.2);
    color: #ffffffa3;
    cursor: not-allowed;
    opacity: 1;
  }
}
