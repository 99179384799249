.txType {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: var(--space-1);
  color: var(--color-text-primary);
  overflow: hidden;
}

.txTypeText {
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  color: var(--color-white);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
